!function(e,t){"object"==typeof exports&&"undefined"!=typeof module?module.exports=t():"function"==typeof define&&define.amd?define(t):e.Popper=t()}(this,function(){"use strict"
function e(e){var t=!1
return function(){t||(t=!0,window.Promise.resolve().then(function(){t=!1,e()}))}}function t(e){var t=!1
return function(){t||(t=!0,setTimeout(function(){t=!1,e()},pe))}}function n(e){var t={}
return e&&"[object Function]"===t.toString.call(e)}function r(e,t){if(1!==e.nodeType)return[]
var n=getComputedStyle(e,null)
return t?n[t]:n}function o(e){return"HTML"===e.nodeName?e:e.parentNode||e.host}function i(e){if(!e)return document.body
switch(e.nodeName){case"HTML":case"BODY":return e.ownerDocument.body
case"#document":return e.body}var t=r(e),n=t.overflow,a=t.overflowX,s=t.overflowY
return/(auto|scroll|overlay)/.test(n+s+a)?e:i(o(e))}function a(e){return 11===e?ce:10===e?he:ce||he}function s(e){if(!e)return document.documentElement
for(var t=a(10)?document.body:null,n=e.offsetParent;n===t&&e.nextElementSibling;)n=(e=e.nextElementSibling).offsetParent
var o=n&&n.nodeName
return o&&"BODY"!==o&&"HTML"!==o?-1!==["TD","TABLE"].indexOf(n.nodeName)&&"static"===r(n,"position")?s(n):n:e?e.ownerDocument.documentElement:document.documentElement}function f(e){var t=e.nodeName
return"BODY"===t?!1:"HTML"===t||s(e.firstElementChild)===e}function p(e){return null!==e.parentNode?p(e.parentNode):e}function u(e,t){if(!(e&&e.nodeType&&t&&t.nodeType))return document.documentElement
var n=e.compareDocumentPosition(t)&Node.DOCUMENT_POSITION_FOLLOWING,r=n?e:t,o=n?t:e,i=document.createRange()
i.setStart(r,0),i.setEnd(o,0)
var a=i.commonAncestorContainer
if(e!==a&&t!==a||r.contains(o))return f(a)?a:s(a)
var l=p(e)
return l.host?u(l.host,t):u(e,p(t).host)}function l(e){var t=arguments.length>1&&void 0!==arguments[1]?arguments[1]:"top",n="top"===t?"scrollTop":"scrollLeft",r=e.nodeName
if("BODY"===r||"HTML"===r){var o=e.ownerDocument.documentElement,i=e.ownerDocument.scrollingElement||o
return i[n]}return e[n]}function d(e,t){var n=arguments.length>2&&void 0!==arguments[2]?arguments[2]:!1,r=l(t,"top"),o=l(t,"left"),i=n?-1:1
return e.top+=r*i,e.bottom+=r*i,e.left+=o*i,e.right+=o*i,e}function c(e,t){var n="x"===t?"Left":"Top",r="Left"===n?"Right":"Bottom"
return parseFloat(e["border"+n+"Width"],10)+parseFloat(e["border"+r+"Width"],10)}function h(e,t,n,r){return Math.max(t["offset"+e],t["scroll"+e],n["client"+e],n["offset"+e],n["scroll"+e],a(10)?n["offset"+e]+r["margin"+("Height"===e?"Top":"Left")]+r["margin"+("Height"===e?"Bottom":"Right")]:0)}function m(){var e=document.body,t=document.documentElement,n=a(10)&&getComputedStyle(t)
return{height:h("Height",e,t,n),width:h("Width",e,t,n)}}function g(e){return be({},e,{right:e.left+e.width,bottom:e.top+e.height})}function v(e){var t={}
try{if(a(10)){t=e.getBoundingClientRect()
var n=l(e,"top"),o=l(e,"left")
t.top+=n,t.left+=o,t.bottom+=n,t.right+=o}else t=e.getBoundingClientRect()}catch(i){}var s={left:t.left,top:t.top,width:t.right-t.left,height:t.bottom-t.top},f="HTML"===e.nodeName?m():{},p=f.width||e.clientWidth||s.right-s.left,u=f.height||e.clientHeight||s.bottom-s.top,d=e.offsetWidth-p,h=e.offsetHeight-u
if(d||h){var v=r(e)
d-=c(v,"x"),h-=c(v,"y"),s.width-=d,s.height-=h}return g(s)}function b(e,t){var n=arguments.length>2&&void 0!==arguments[2]?arguments[2]:!1,o=a(10),s="HTML"===t.nodeName,f=v(e),p=v(t),u=i(e),l=r(t),c=parseFloat(l.borderTopWidth,10),h=parseFloat(l.borderLeftWidth,10)
n&&"HTML"===t.nodeName&&(p.top=Math.max(p.top,0),p.left=Math.max(p.left,0))
var m=g({top:f.top-p.top-c,left:f.left-p.left-h,width:f.width,height:f.height})
if(m.marginTop=0,m.marginLeft=0,!o&&s){var b=parseFloat(l.marginTop,10),w=parseFloat(l.marginLeft,10)
m.top-=c-b,m.bottom-=c-b,m.left-=h-w,m.right-=h-w,m.marginTop=b,m.marginLeft=w}return(o&&!n?t.contains(u):t===u&&"BODY"!==u.nodeName)&&(m=d(m,t)),m}function w(e){var t=arguments.length>1&&void 0!==arguments[1]?arguments[1]:!1,n=e.ownerDocument.documentElement,r=b(e,n),o=Math.max(n.clientWidth,window.innerWidth||0),i=Math.max(n.clientHeight,window.innerHeight||0),a=t?0:l(n),s=t?0:l(n,"left"),f={top:a-r.top+r.marginTop,left:s-r.left+r.marginLeft,width:o,height:i}
return g(f)}function y(e){var t=e.nodeName
return"BODY"===t||"HTML"===t?!1:"fixed"===r(e,"position")?!0:y(o(e))}function E(e){if(!e||!e.parentElement||a())return document.documentElement
for(var t=e.parentElement;t&&"none"===r(t,"transform");)t=t.parentElement
return t||document.documentElement}function x(e,t,n,r){var a=arguments.length>4&&void 0!==arguments[4]?arguments[4]:!1,s={top:0,left:0},f=a?E(e):u(e,t)
if("viewport"===r)s=w(f,a)
else{var p=void 0
"scrollParent"===r?(p=i(o(t)),"BODY"===p.nodeName&&(p=e.ownerDocument.documentElement)):p="window"===r?e.ownerDocument.documentElement:r
var l=b(p,f,a)
if("HTML"!==p.nodeName||y(f))s=l
else{var d=m(),c=d.height,h=d.width
s.top+=l.top-l.marginTop,s.bottom=c+l.top,s.left+=l.left-l.marginLeft,s.right=h+l.left}}return s.left+=n,s.top+=n,s.right-=n,s.bottom-=n,s}function O(e){var t=e.width,n=e.height
return t*n}function L(e,t,n,r,o){var i=arguments.length>5&&void 0!==arguments[5]?arguments[5]:0
if(-1===e.indexOf("auto"))return e
var a=x(n,r,i,o),s={top:{width:a.width,height:t.top-a.top},right:{width:a.right-t.right,height:a.height},bottom:{width:a.width,height:a.bottom-t.bottom},left:{width:t.left-a.left,height:a.height}},f=Object.keys(s).map(function(e){return be({key:e},s[e],{area:O(s[e])})}).sort(function(e,t){return t.area-e.area}),p=f.filter(function(e){var t=e.width,r=e.height
return t>=n.clientWidth&&r>=n.clientHeight}),u=p.length>0?p[0].key:f[0].key,l=e.split("-")[1]
return u+(l?"-"+l:"")}function T(e,t,n){var r=arguments.length>3&&void 0!==arguments[3]?arguments[3]:null,o=r?E(t):u(t,n)
return b(n,o,r)}function M(e){var t=getComputedStyle(e),n=parseFloat(t.marginTop)+parseFloat(t.marginBottom),r=parseFloat(t.marginLeft)+parseFloat(t.marginRight),o={width:e.offsetWidth+r,height:e.offsetHeight+n}
return o}function C(e){var t={left:"right",right:"left",bottom:"top",top:"bottom"}
return e.replace(/left|right|bottom|top/g,function(e){return t[e]})}function N(e,t,n){n=n.split("-")[0]
var r=M(e),o={width:r.width,height:r.height},i=-1!==["right","left"].indexOf(n),a=i?"top":"left",s=i?"left":"top",f=i?"height":"width",p=i?"width":"height"
return o[a]=t[a]+t[f]/2-r[f]/2,n===s?o[s]=t[s]-r[p]:o[s]=t[C(s)],o}function F(e,t){return Array.prototype.find?e.find(t):e.filter(t)[0]}function D(e,t,n){if(Array.prototype.findIndex)return e.findIndex(function(e){return e[t]===n})
var r=F(e,function(e){return e[t]===n})
return e.indexOf(r)}function k(e,t,r){var o=void 0===r?e:e.slice(0,D(e,"name",r))
return o.forEach(function(e){e["function"]&&console.warn("`modifier.function` is deprecated, use `modifier.fn`!")
var r=e["function"]||e.fn
e.enabled&&n(r)&&(t.offsets.popper=g(t.offsets.popper),t.offsets.reference=g(t.offsets.reference),t=r(t,e))}),t}function S(){if(!this.state.isDestroyed){var e={instance:this,styles:{},arrowStyles:{},attributes:{},flipped:!1,offsets:{}}
e.offsets.reference=T(this.state,this.popper,this.reference,this.options.positionFixed),e.placement=L(this.options.placement,e.offsets.reference,this.popper,this.reference,this.options.modifiers.flip.boundariesElement,this.options.modifiers.flip.padding),e.originalPlacement=e.placement,e.positionFixed=this.options.positionFixed,e.offsets.popper=N(this.popper,e.offsets.reference,e.placement),e.offsets.popper.position=this.options.positionFixed?"fixed":"absolute",e=k(this.modifiers,e),this.state.isCreated?this.options.onUpdate(e):(this.state.isCreated=!0,this.options.onCreate(e))}}function W(e,t){return e.some(function(e){var n=e.name,r=e.enabled
return r&&n===t})}function P(e){for(var t=[!1,"ms","Webkit","Moz","O"],n=e.charAt(0).toUpperCase()+e.slice(1),r=0;r<t.length;r++){var o=t[r],i=o?""+o+n:e
if(void 0!==document.body.style[i])return i}return null}function H(){return this.state.isDestroyed=!0,W(this.modifiers,"applyStyle")&&(this.popper.removeAttribute("x-placement"),this.popper.style.position="",this.popper.style.top="",this.popper.style.left="",this.popper.style.right="",this.popper.style.bottom="",this.popper.style.willChange="",this.popper.style[P("transform")]=""),this.disableEventListeners(),this.options.removeOnDestroy&&this.popper.parentNode.removeChild(this.popper),this}function A(e){var t=e.ownerDocument
return t?t.defaultView:window}function B(e,t,n,r){var o="BODY"===e.nodeName,a=o?e.ownerDocument.defaultView:e
a.addEventListener(t,n,{passive:!0}),o||B(i(a.parentNode),t,n,r),r.push(a)}function j(e,t,n,r){n.updateBound=r,A(e).addEventListener("resize",n.updateBound,{passive:!0})
var o=i(e)
return B(o,"scroll",n.updateBound,n.scrollParents),n.scrollElement=o,n.eventsEnabled=!0,n}function I(){this.state.eventsEnabled||(this.state=j(this.reference,this.options,this.state,this.scheduleUpdate))}function R(e,t){return A(e).removeEventListener("resize",t.updateBound),t.scrollParents.forEach(function(e){e.removeEventListener("scroll",t.updateBound)}),t.updateBound=null,t.scrollParents=[],t.scrollElement=null,t.eventsEnabled=!1,t}function U(){this.state.eventsEnabled&&(cancelAnimationFrame(this.scheduleUpdate),this.state=R(this.reference,this.state))}function Y(e){return""!==e&&!isNaN(parseFloat(e))&&isFinite(e)}function q(e,t){Object.keys(t).forEach(function(n){var r="";-1!==["width","height","top","right","bottom","left"].indexOf(n)&&Y(t[n])&&(r="px"),e.style[n]=t[n]+r})}function K(e,t){Object.keys(t).forEach(function(n){var r=t[n]
r!==!1?e.setAttribute(n,t[n]):e.removeAttribute(n)})}function z(e){return q(e.instance.popper,e.styles),K(e.instance.popper,e.attributes),e.arrowElement&&Object.keys(e.arrowStyles).length&&q(e.arrowElement,e.arrowStyles),e}function G(e,t,n,r,o){var i=T(o,t,e,n.positionFixed),a=L(n.placement,i,t,e,n.modifiers.flip.boundariesElement,n.modifiers.flip.padding)
return t.setAttribute("x-placement",a),q(t,{position:n.positionFixed?"fixed":"absolute"}),n}function V(e,t){var n=t.x,r=t.y,o=e.offsets.popper,i=F(e.instance.modifiers,function(e){return"applyStyle"===e.name}).gpuAcceleration
void 0!==i&&console.warn("WARNING: `gpuAcceleration` option moved to `computeStyle` modifier and will not be supported in future versions of Popper.js!")
var a=void 0!==i?i:t.gpuAcceleration,f=s(e.instance.popper),p=v(f),u={position:o.position},l={left:Math.floor(o.left),top:Math.round(o.top),bottom:Math.round(o.bottom),right:Math.floor(o.right)},d="bottom"===n?"top":"bottom",c="right"===r?"left":"right",h=P("transform"),m=void 0,g=void 0
if(g="bottom"===d?-p.height+l.bottom:l.top,m="right"===c?-p.width+l.right:l.left,a&&h)u[h]="translate3d("+m+"px, "+g+"px, 0)",u[d]=0,u[c]=0,u.willChange="transform"
else{var b="bottom"===d?-1:1,w="right"===c?-1:1
u[d]=g*b,u[c]=m*w,u.willChange=d+", "+c}var y={"x-placement":e.placement}
return e.attributes=be({},y,e.attributes),e.styles=be({},u,e.styles),e.arrowStyles=be({},e.offsets.arrow,e.arrowStyles),e}function _(e,t,n){var r=F(e,function(e){var n=e.name
return n===t}),o=!!r&&e.some(function(e){return e.name===n&&e.enabled&&e.order<r.order})
if(!o){var i="`"+t+"`",a="`"+n+"`"
console.warn(a+" modifier is required by "+i+" modifier in order to work, be sure to include it before "+i+"!")}return o}function X(e,t){var n
if(!_(e.instance.modifiers,"arrow","keepTogether"))return e
var o=t.element
if("string"==typeof o){if(o=e.instance.popper.querySelector(o),!o)return e}else if(!e.instance.popper.contains(o))return console.warn("WARNING: `arrow.element` must be child of its popper element!"),e
var i=e.placement.split("-")[0],a=e.offsets,s=a.popper,f=a.reference,p=-1!==["left","right"].indexOf(i),u=p?"height":"width",l=p?"Top":"Left",d=l.toLowerCase(),c=p?"left":"top",h=p?"bottom":"right",m=M(o)[u]
f[h]-m<s[d]&&(e.offsets.popper[d]-=s[d]-(f[h]-m)),f[d]+m>s[h]&&(e.offsets.popper[d]+=f[d]+m-s[h]),e.offsets.popper=g(e.offsets.popper)
var v=f[d]+f[u]/2-m/2,b=r(e.instance.popper),w=parseFloat(b["margin"+l],10),y=parseFloat(b["border"+l+"Width"],10),E=v-e.offsets.popper[d]-w-y
return E=Math.max(Math.min(s[u]-m,E),0),e.arrowElement=o,e.offsets.arrow=(n={},ve(n,d,Math.round(E)),ve(n,c,""),n),e}function J(e){return"end"===e?"start":"start"===e?"end":e}function Q(e){var t=arguments.length>1&&void 0!==arguments[1]?arguments[1]:!1,n=ye.indexOf(e),r=ye.slice(n+1).concat(ye.slice(0,n))
return t?r.reverse():r}function Z(e,t){if(W(e.instance.modifiers,"inner"))return e
if(e.flipped&&e.placement===e.originalPlacement)return e
var n=x(e.instance.popper,e.instance.reference,t.padding,t.boundariesElement,e.positionFixed),r=e.placement.split("-")[0],o=C(r),i=e.placement.split("-")[1]||"",a=[]
switch(t.behavior){case Ee.FLIP:a=[r,o]
break
case Ee.CLOCKWISE:a=Q(r)
break
case Ee.COUNTERCLOCKWISE:a=Q(r,!0)
break
default:a=t.behavior}return a.forEach(function(s,f){if(r!==s||a.length===f+1)return e
r=e.placement.split("-")[0],o=C(r)
var p=e.offsets.popper,u=e.offsets.reference,l=Math.floor,d="left"===r&&l(p.right)>l(u.left)||"right"===r&&l(p.left)<l(u.right)||"top"===r&&l(p.bottom)>l(u.top)||"bottom"===r&&l(p.top)<l(u.bottom),c=l(p.left)<l(n.left),h=l(p.right)>l(n.right),m=l(p.top)<l(n.top),g=l(p.bottom)>l(n.bottom),v="left"===r&&c||"right"===r&&h||"top"===r&&m||"bottom"===r&&g,b=-1!==["top","bottom"].indexOf(r),w=!!t.flipVariations&&(b&&"start"===i&&c||b&&"end"===i&&h||!b&&"start"===i&&m||!b&&"end"===i&&g);(d||v||w)&&(e.flipped=!0,(d||v)&&(r=a[f+1]),w&&(i=J(i)),e.placement=r+(i?"-"+i:""),e.offsets.popper=be({},e.offsets.popper,N(e.instance.popper,e.offsets.reference,e.placement)),e=k(e.instance.modifiers,e,"flip"))}),e}function $(e){var t=e.offsets,n=t.popper,r=t.reference,o=e.placement.split("-")[0],i=Math.floor,a=-1!==["top","bottom"].indexOf(o),s=a?"right":"bottom",f=a?"left":"top",p=a?"width":"height"
return n[s]<i(r[f])&&(e.offsets.popper[f]=i(r[f])-n[p]),n[f]>i(r[s])&&(e.offsets.popper[f]=i(r[s])),e}function ee(e,t,n,r){var o=e.match(/((?:\-|\+)?\d*\.?\d*)(.*)/),i=+o[1],a=o[2]
if(!i)return e
if(0===a.indexOf("%")){var s=void 0
switch(a){case"%p":s=n
break
case"%":case"%r":default:s=r}var f=g(s)
return f[t]/100*i}if("vh"===a||"vw"===a){var p=void 0
return p="vh"===a?Math.max(document.documentElement.clientHeight,window.innerHeight||0):Math.max(document.documentElement.clientWidth,window.innerWidth||0),p/100*i}return i}function te(e,t,n,r){var o=[0,0],i=-1!==["right","left"].indexOf(r),a=e.split(/(\+|\-)/).map(function(e){return e.trim()}),s=a.indexOf(F(a,function(e){return-1!==e.search(/,|\s/)}))
a[s]&&-1===a[s].indexOf(",")&&console.warn("Offsets separated by white space(s) are deprecated, use a comma (,) instead.")
var f=/\s*,\s*|\s+/,p=-1!==s?[a.slice(0,s).concat([a[s].split(f)[0]]),[a[s].split(f)[1]].concat(a.slice(s+1))]:[a]
return p=p.map(function(e,r){var o=(1===r?!i:i)?"height":"width",a=!1
return e.reduce(function(e,t){return""===e[e.length-1]&&-1!==["+","-"].indexOf(t)?(e[e.length-1]=t,a=!0,e):a?(e[e.length-1]+=t,a=!1,e):e.concat(t)},[]).map(function(e){return ee(e,o,t,n)})}),p.forEach(function(e,t){e.forEach(function(n,r){Y(n)&&(o[t]+=n*("-"===e[r-1]?-1:1))})}),o}function ne(e,t){var n=t.offset,r=e.placement,o=e.offsets,i=o.popper,a=o.reference,s=r.split("-")[0],f=void 0
return f=Y(+n)?[+n,0]:te(n,i,a,s),"left"===s?(i.top+=f[0],i.left-=f[1]):"right"===s?(i.top+=f[0],i.left+=f[1]):"top"===s?(i.left+=f[0],i.top-=f[1]):"bottom"===s&&(i.left+=f[0],i.top+=f[1]),e.popper=i,e}function re(e,t){var n=t.boundariesElement||s(e.instance.popper)
e.instance.reference===n&&(n=s(n))
var r=P("transform"),o=e.instance.popper.style,i=o.top,a=o.left,f=o[r]
o.top="",o.left="",o[r]=""
var p=x(e.instance.popper,e.instance.reference,t.padding,n,e.positionFixed)
o.top=i,o.left=a,o[r]=f,t.boundaries=p
var u=t.priority,l=e.offsets.popper,d={primary:function(e){var n=l[e]
return l[e]<p[e]&&!t.escapeWithReference&&(n=Math.max(l[e],p[e])),ve({},e,n)},secondary:function(e){var n="right"===e?"left":"top",r=l[n]
return l[e]>p[e]&&!t.escapeWithReference&&(r=Math.min(l[n],p[e]-("right"===e?l.width:l.height))),ve({},n,r)}}
return u.forEach(function(e){var t=-1!==["left","top"].indexOf(e)?"primary":"secondary"
l=be({},l,d[t](e))}),e.offsets.popper=l,e}function oe(e){var t=e.placement,n=t.split("-")[0],r=t.split("-")[1]
if(r){var o=e.offsets,i=o.reference,a=o.popper,s=-1!==["bottom","top"].indexOf(n),f=s?"left":"top",p=s?"width":"height",u={start:ve({},f,i[f]),end:ve({},f,i[f]+i[p]-a[p])}
e.offsets.popper=be({},a,u[r])}return e}function ie(e){if(!_(e.instance.modifiers,"hide","preventOverflow"))return e
var t=e.offsets.reference,n=F(e.instance.modifiers,function(e){return"preventOverflow"===e.name}).boundaries
if(t.bottom<n.top||t.left>n.right||t.top>n.bottom||t.right<n.left){if(e.hide===!0)return e
e.hide=!0,e.attributes["x-out-of-boundaries"]=""}else{if(e.hide===!1)return e
e.hide=!1,e.attributes["x-out-of-boundaries"]=!1}return e}function ae(e){var t=e.placement,n=t.split("-")[0],r=e.offsets,o=r.popper,i=r.reference,a=-1!==["left","right"].indexOf(n),s=-1===["top","left"].indexOf(n)
return o[a?"left":"top"]=i[n]-(s?o[a?"width":"height"]:0),e.placement=C(t),e.offsets.popper=g(o),e}for(var se="undefined"!=typeof window&&"undefined"!=typeof document,fe=["Edge","Trident","Firefox"],pe=0,ue=0;ue<fe.length;ue+=1)if(se&&navigator.userAgent.indexOf(fe[ue])>=0){pe=1
break}var le=se&&window.Promise,de=le?e:t,ce=se&&!(!window.MSInputMethodContext||!document.documentMode),he=se&&/MSIE 10/.test(navigator.userAgent),me=function(e,t){if(!(e instanceof t))throw new TypeError("Cannot call a class as a function")},ge=function(){function e(e,t){for(var n=0;n<t.length;n++){var r=t[n]
r.enumerable=r.enumerable||!1,r.configurable=!0,"value"in r&&(r.writable=!0),Object.defineProperty(e,r.key,r)}}return function(t,n,r){return n&&e(t.prototype,n),r&&e(t,r),t}}(),ve=function(e,t,n){return t in e?Object.defineProperty(e,t,{value:n,enumerable:!0,configurable:!0,writable:!0}):e[t]=n,e},be=Object.assign||function(e){for(var t=1;t<arguments.length;t++){var n=arguments[t]
for(var r in n)Object.prototype.hasOwnProperty.call(n,r)&&(e[r]=n[r])}return e},we=["auto-start","auto","auto-end","top-start","top","top-end","right-start","right","right-end","bottom-end","bottom","bottom-start","left-end","left","left-start"],ye=we.slice(3),Ee={FLIP:"flip",CLOCKWISE:"clockwise",COUNTERCLOCKWISE:"counterclockwise"},xe={shift:{order:100,enabled:!0,fn:oe},offset:{order:200,enabled:!0,fn:ne,offset:0},preventOverflow:{order:300,enabled:!0,fn:re,priority:["left","right","top","bottom"],padding:5,boundariesElement:"scrollParent"},keepTogether:{order:400,enabled:!0,fn:$},arrow:{order:500,enabled:!0,fn:X,element:"[x-arrow]"},flip:{order:600,enabled:!0,fn:Z,behavior:"flip",padding:5,boundariesElement:"viewport"},inner:{order:700,enabled:!1,fn:ae},hide:{order:800,enabled:!0,fn:ie},computeStyle:{order:850,enabled:!0,fn:V,gpuAcceleration:!0,x:"bottom",y:"right"},applyStyle:{order:900,enabled:!0,fn:z,onLoad:G,gpuAcceleration:void 0}},Oe={placement:"bottom",positionFixed:!1,eventsEnabled:!0,removeOnDestroy:!1,onCreate:function(){},onUpdate:function(){},modifiers:xe},Le=function(){function e(t,r){var o=this,i=arguments.length>2&&void 0!==arguments[2]?arguments[2]:{}
me(this,e),this.scheduleUpdate=function(){return requestAnimationFrame(o.update)},this.update=de(this.update.bind(this)),this.options=be({},e.Defaults,i),this.state={isDestroyed:!1,isCreated:!1,scrollParents:[]},this.reference=t&&t.jquery?t[0]:t,this.popper=r&&r.jquery?r[0]:r,this.options.modifiers={},Object.keys(be({},e.Defaults.modifiers,i.modifiers)).forEach(function(t){o.options.modifiers[t]=be({},e.Defaults.modifiers[t]||{},i.modifiers?i.modifiers[t]:{})}),this.modifiers=Object.keys(this.options.modifiers).map(function(e){return be({name:e},o.options.modifiers[e])}).sort(function(e,t){return e.order-t.order}),this.modifiers.forEach(function(e){e.enabled&&n(e.onLoad)&&e.onLoad(o.reference,o.popper,o.options,e,o.state)}),this.update()
var a=this.options.eventsEnabled
a&&this.enableEventListeners(),this.state.eventsEnabled=a}return ge(e,[{key:"update",value:function(){return S.call(this)}},{key:"destroy",value:function(){return H.call(this)}},{key:"enableEventListeners",value:function(){return I.call(this)}},{key:"disableEventListeners",value:function(){return U.call(this)}}]),e}()
return Le.Utils=("undefined"!=typeof window?window:global).PopperUtils,Le.placements=we,Le.Defaults=Oe,Le})
